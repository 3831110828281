import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import useIsViewport from 'hooks/useIsViewport';

const TooltipDialog = ({
  title,
  info,
  icon = <MdInfo size={20} />,
}: {
  title: string;
  info: string;
  icon?: ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const isMobile = useIsViewport('md');

  if (!isMobile) {
    return (
      <Tooltip
        color="primary"
        title={<Typography variant="body1">{info}</Typography>}
        arrow
        placement="right"
        enterTouchDelay={0}
      >
        <IconButton color="primary" aria-label="info" sx={{ cursor: 'default' }}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <>
      <IconButton color="primary" aria-label="info" onClick={handleDialogOpen}>
        {icon}
      </IconButton>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{info}</DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TooltipDialog;
