import { Role } from 'generated/graphql';

export enum UserType {
  Admin = 'Admin',
  Buyer = 'Buyer',
  Seller = 'Seller',
}

export enum UserStatus {
  Registering = 'Registering',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export const ROBOT_TOKEN_EMAIL = 'robot@nile.ag';

export enum UserTheme {
  Dark = 'Dark',
  Light = 'Light',
  System = 'System',
}
export interface AuthUser {
  name: string;
  userId: string;
  theme: UserTheme;
  status: UserStatus;
  email: string;
  intercomUserHash: string;
  multiProfile: boolean;
  profileId: number;
  adminProfileId: number;
  userType: UserType;
  hubId: number;
  roles: Role[];
  emailVerified: boolean;
}
export interface UserClaims {
  name: string;
  profileId: number;
  admin_profile_id: number;
  userType: UserType;
  email: string;
  hubId: number;
  status: UserStatus;
  multiProfile: boolean;
  user_id: string;
  theme: UserTheme;
  intercomUserHash: string;
  roles: Role[];
  email_verified: boolean;
}

export const defaultRobotClaims = {
  name: 'Robot',
  email: ROBOT_TOKEN_EMAIL,
  userType: UserType.Admin,
  profileId: 1,
  admin_profile_id: 1,
  status: UserStatus.Approved,
  theme: UserTheme.Light,
  user_id: '',
  intercomUserHash: '',
  multiProfile: false,
  hubId: 1,
  roles: [],
};
