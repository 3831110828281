import { Stack, Typography } from '@mui/material';
import chartEmptyState from './ChartEmptyState.svg';

export enum ChartEmptyStateVariant {
  avgMaxMin = 'AvgMaxMin',
}

const getEmptyStateVariant = ({ variant }: { variant: ChartEmptyStateVariant }) => {
  switch (variant) {
    default:
      return {
        image: chartEmptyState ?? '',
        title: 'Data unavailable',
        subtitle: 'Data is currently unavailable for reference pricing trends and insights. Please return later.',
      };
  }
};

const ChartContainerEmptyState = ({
  variant = ChartEmptyStateVariant.avgMaxMin,
}: {
  variant?: ChartEmptyStateVariant;
}) => {
  const emptyStateData = getEmptyStateVariant({ variant });

  return (
    <Stack alignItems="center" justifyItems="center">
      <Stack
        alignItems="center"
        justifyItems="center"
        sx={(theme) => ({
          padding: theme.spacing(2, 5),
          width: '70%',
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        })}
      >
        <img src={emptyStateData.image} style={{ width: '40px', height: '40px' }} alt="" />
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" color="primary">
            {emptyStateData.title}
          </Typography>
          <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[600] })}>
            {emptyStateData.subtitle}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export default ChartContainerEmptyState;
