import { getToken } from 'firebase/messaging';
import { ROBOT_TOKEN_EMAIL } from 'interfaces/user';
import { useCallback, useEffect } from 'react';
import { auth, getFirebaseMessaging } from 'services/firebase';
import { isLocal } from 'config/env';
import useLogSentryWithContext from 'hooks/useLogSentryWithContext';
import { isIOS } from 'utils/is-ios';
import { useRegisterDeviceMutation } from 'generated/graphql';

const STORAGE_KEY = 'deviceToken';

const isSupported = () => 'Notification' in window;

const useSyncPushToken = () => {
  const user = auth.currentUser;
  const [, executeMutation] = useRegisterDeviceMutation();
  const logError = useLogSentryWithContext();

  const handleSyncIOS = useCallback(async () => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (!token) {
      return;
    }
    await executeMutation({
      input: {
        token,
      },
    });
  }, [executeMutation]);

  const handleSyncNew = useCallback(async () => {
    if (!isLocal) {
      try {
        const messaging = await getFirebaseMessaging();

        if (!messaging || Notification.permission === 'denied') {
          localStorage.removeItem(STORAGE_KEY);
          return;
        }

        const registration = await navigator.serviceWorker.ready;
        const storageToken = localStorage.getItem(STORAGE_KEY);
        const token = await getToken(messaging, {
          vapidKey: import.meta.env.REACT_APP_FIREBASE_VAPID_TOKEN,
          serviceWorkerRegistration: registration,
        });

        if (storageToken !== token) {
          localStorage.setItem(STORAGE_KEY, token);
          await executeMutation({
            input: {
              token,
            },
          });
        }
      } catch (error: any) {
        localStorage.removeItem(STORAGE_KEY);
        logError(error);
      }
    }
  }, [executeMutation, logError]);

  const handleSync = useCallback(() => {
    if (isIOS() || !isSupported()) {
      handleSyncIOS();
      return;
    }
    handleSyncNew();
  }, [handleSyncNew, handleSyncIOS]);

  const handleShouldSync = useCallback(() => {
    const isRobotUser = user?.email === ROBOT_TOKEN_EMAIL;
    if (user?.email && !isRobotUser) {
      handleSync();
    }
  }, [user?.email, handleSync]);

  // If a user closes the app, goes into settings, enables notifications, and returns, it should prompt again
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        handleShouldSync();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleShouldSync]);

  useEffect(() => {
    const handleStorageChange = (event: MessageEvent) => {
      if (event.data && event.data.type === 'storageChange') {
        handleSyncIOS();
      }
    };

    window.addEventListener('message', handleStorageChange);

    return () => {
      window.removeEventListener('message', handleStorageChange);
    };
  }, [handleSyncIOS]);

  useEffect(() => {
    handleShouldSync();
  }, [handleShouldSync]);

  return handleSync;
};

export default useSyncPushToken;
