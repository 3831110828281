import { getIdTokenResult, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { AuthUser, UserClaims, UserStatus, UserType } from 'interfaces/user';
import {
  useContext,
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { logout } from 'services/auth';
import { User, auth } from 'services/firebase';
import { logError } from 'services/logging';
import { NILE_ADMIN_PROFILE_ID } from 'services/theming';
import { useGraphState } from 'components/GraphProvider';
import { Role } from 'generated/graphql';

export const getUserFromParsedIdToken = (parsedToken: any): AuthUser => {
  const claims = parsedToken?.claims as UserClaims;

  return {
    name: claims.name,
    email: claims.email,
    userType: claims.userType,
    profileId: claims.profileId,
    adminProfileId: claims.admin_profile_id ?? NILE_ADMIN_PROFILE_ID,
    hubId: claims.hubId,
    userId: claims.user_id,
    theme: claims.theme,
    status: claims.status,
    intercomUserHash: claims.intercomUserHash,
    multiProfile: claims.multiProfile,
    roles: claims.roles,
    emailVerified: claims.email_verified,
  };
};

export interface IUser {
  user: AuthUser | null;
  setUser: Dispatch<SetStateAction<AuthUser | null>>;
  isAdmin: boolean;
  isNileUser: boolean;
  isSeller: boolean;
  isBuyer: boolean;
  isRegistering: boolean;
  isInitialised: boolean;
  isPendingReview: boolean;
  onLogout: () => Promise<void>;
  onRefreshUser: () => Promise<AuthUser | undefined>;
  hasRole: (role: Role) => boolean;
}
export const UserContext = createContext<null | IUser>(null);

export const UserProvider = ({ children, initial = null }: { children: ReactNode; initial?: null | AuthUser }) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [user, setUser] = useState<null | AuthUser>(initial);
  const { resetClient } = useGraphState();
  const isNileUser = user?.adminProfileId === NILE_ADMIN_PROFILE_ID;
  const isRegistering = user?.status === undefined || user?.status === UserStatus.Registering;
  const isPendingReview = !isRegistering && user?.status !== UserStatus.Approved;

  const onTokenChange = useCallback(
    async (newUser: User | null) => {
      if (newUser) {
        const result = await getIdTokenResult(newUser);
        setUser(getUserFromParsedIdToken(result));
      } else {
        setUser(null);
      }
      setIsInitialised(true);
    },
    [setUser],
  );

  const handleLogout = useCallback(async () => {
    resetClient();
    setUser(null);
    await logout();
  }, [resetClient]);

  const handleRefreshUser = useCallback(async () => {
    try {
      if (!auth.currentUser) return;

      const authUser = await getIdTokenResult(auth.currentUser, true);
      const parsedUser = getUserFromParsedIdToken(authUser);
      setUser(parsedUser);
      return parsedUser;
    } catch (err: any) {
      logError(err);
    }
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, onTokenChange);
    onIdTokenChanged(auth, onTokenChange);
  }, [onTokenChange]);

  useEffect(() => {
    window.addEventListener('focus', handleRefreshUser);
    window.addEventListener('visibilitychange', handleRefreshUser);
    return () => {
      window.removeEventListener('focus', handleRefreshUser);
      window.removeEventListener('visibilitychange', handleRefreshUser);
    };
  }, [handleRefreshUser]);

  const value = useMemo(() => {
    const hasRole = (role: Role) => !!user?.roles?.includes(role);

    return {
      user,
      setUser,
      isRegistering,
      isPendingReview,
      isAdmin: user?.userType === UserType.Admin,
      isSeller: user?.userType === UserType.Seller,
      isBuyer: user?.userType === UserType.Buyer,
      isNileUser,
      onLogout: handleLogout,
      onRefreshUser: handleRefreshUser,
      isInitialised,
      hasRole,
    };
  }, [user, setUser, handleLogout, isRegistering, isPendingReview, handleRefreshUser, isNileUser, isInitialised]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const result = useContext(UserContext);
  if (!result) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return result;
};

export default useUser;
