import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  SxProps,
  Switch,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Suspense } from 'react';
import { DEFAULT_STRING_DELIMITER } from 'services/api-constants';
import { BaseTheme } from 'config/base-theme';
import { mergeSx } from 'utils/merge-sx';
import { Field, FormType, InputType } from 'generated/graphql';
import FileUploader from './FileUploader';
import ImageUploadField from './ImageUploader/ImageUploadField';
import MultiSlider from './MultiSlider';
import NumberField from './NumberField';
import NumberFormatRands from './NumberFormatRands';
import PasswordTextfield from './PasswordTextField';

const CalculatedField = lazy(() => import('./CalculatedField'));

const DynamicInputField = ({
  hasWhiteFields = false,
  field,
  autoFocus,
  onChange,
  className,
  sx,
  disabled = false,
  allFields,
  fieldIndex = 0,
  isHorizontal = false,
  isQCStorage = false,
  formType,
}: {
  hasWhiteFields?: boolean;
  field: Field;
  className?: string;
  sx?: SxProps<BaseTheme>;
  autoFocus?: boolean;
  onChange(value: string, values?: string[]): void;
  allFields: Field[];
  fieldIndex?: number;
  disabled?: boolean;
  isHorizontal?: boolean;
  isQCStorage?: boolean;
  formType: FormType;
}) => {
  const isDisabled = !!(!field || field.disabled || disabled);
  const isReadOnly = !!field.readOnly;

  switch (field.type) {
    case InputType.Hidden:
      return null;
    case InputType.Checkbox:
      return (
        <FormControlLabel
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
          }))}
          control={
            <Checkbox
              readOnly={isReadOnly}
              color="secondary"
              disabled={isDisabled}
              checked={field.value === 'on'}
              onChange={(event) => {
                const val = event.target.checked ? 'on' : '';
                onChange(val);
              }}
              name={field.label}
            />
          }
          label={field.label}
        />
      );
    case InputType.Switch:
      return (
        <FormControlLabel
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
          }))}
          control={
            <Switch
              color="secondary"
              readOnly={isReadOnly}
              disabled={isDisabled}
              checked={field.value === 'on'}
              onChange={(event) => {
                const val = event.target.checked ? 'on' : '';
                onChange(val);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={field.label}
        />
      );
    case InputType.Select:
      return (
        <FormControl
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          variant="outlined"
          margin="dense"
          fullWidth
        >
          {field.value.length > 0 && <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>}
          <Select
            native
            readOnly={isReadOnly}
            labelId={`${field.id}-label`}
            id={field.id.toString()}
            label={field.label}
            value={field.value}
            disabled={isDisabled}
            onChange={(event) => {
              const val = event.target.value as string;
              onChange(val);
            }}
            data-testid={field.id}
          >
            {field.selectOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    case InputType.Currency:
      return (
        <FormControl fullWidth>
          <InputLabel htmlFor="component-outlined">{field.label}</InputLabel>
          <OutlinedInput
            id={field.id.toString()}
            key={`${field.id}`}
            label={field.label}
            value={field.value}
            disabled={isDisabled}
            autoFocus={autoFocus}
            onChange={(event) => onChange(event.target.value)}
            sx={mergeSx(sx ?? {}, (theme) => ({
              background: hasWhiteFields ? theme.palette.background.paper : 'initial',
              borderRadius: theme.spacing(1),
            }))}
            className={className}
            size="small"
            defaultValue="Composed TextField"
            margin="dense"
            inputComponent={NumberFormatRands as any}
          />
        </FormControl>
      );
    case InputType.Multiselect:
      return (
        <Autocomplete
          multiple
          readOnly={isReadOnly}
          className={className}
          sx={(theme) => ({
            marginBottom: 1,
            maxWidth: 600,
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          })}
          id={field.id.toString()}
          options={field.selectOptions}
          getOptionLabel={(o) => o.label}
          disabled={isDisabled}
          filterSelectedOptions
          value={field.selectOptions.filter(
            (o) => o.value && field.value.split(DEFAULT_STRING_DELIMITER).includes(o.value),
          )}
          onChange={(_, options) => {
            const values = options.map((option) => option.value);
            const value = values.join(DEFAULT_STRING_DELIMITER);
            onChange(value, values);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              autoFocus={autoFocus}
              fullWidth
              label={field.label}
            />
          )}
        />
      );
    case InputType.Autocomplete:
      return (
        <Autocomplete
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          readOnly={isReadOnly}
          id={field.id.toString()}
          options={field.selectOptions}
          getOptionLabel={(o) => o.label}
          disabled={isDisabled}
          value={field.selectOptions.find((o) => o.value === field.value) ?? null}
          onChange={(_, newSelected) => {
            const val = newSelected?.value;
            onChange(val || '');
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
              autoFocus={autoFocus}
              fullWidth
              label={field.label}
            />
          )}
        />
      );
    case InputType.Date:
      return (
        <TextField
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          variant="outlined"
          margin="dense"
          autoFocus={autoFocus}
          fullWidth
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          slotProps={{ htmlInput: { readOnly: isReadOnly }, inputLabel: { shrink: true } }}
          disabled={isDisabled}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
    case InputType.Images:
    case InputType.Files:
      if (isHorizontal)
        return (
          <ImageUploadField
            label={field.label}
            hasWhiteFields={hasWhiteFields}
            files={field.values?.length > 0 ? field.values : field.value.split(';').filter(Boolean)}
            onChange={(values) => {
              const value = values.join(';');
              onChange(value, values);
            }}
            relatedField={allFields[fieldIndex - 1].value}
            id={field.id.toString()}
          />
        );
      else
        return (
          <FileUploader
            label={field.label}
            files={field.values?.length > 0 ? field.values : field.value.split(';').filter(Boolean)}
            onChange={(values) => {
              const value = values.join(';');
              onChange(value, values);
            }}
            isQCStorage={isQCStorage}
            formType={formType}
            id={field.id.toString()}
          />
        );
    case InputType.Multiline:
      return (
        <TextField
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          variant="outlined"
          margin="dense"
          disabled={isDisabled}
          autoFocus={autoFocus}
          slotProps={{ htmlInput: { readOnly: isReadOnly }, inputLabel: { shrink: true } }}
          fullWidth
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          multiline
          rows={4}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
    case InputType.Number:
      return (
        <NumberField
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          slotProps={{ htmlInput: { readOnly: isReadOnly } }}
          disabled={isDisabled}
          margin="dense"
          autoFocus={autoFocus}
          fullWidth
          id={field.id.toString()}
          label={field.label}
          value={field.value}
          onChange={(_, value) => onChange(value || '')}
        />
      );
    case InputType.Calculated:
      return (
        <Suspense fallback={null}>
          <CalculatedField
            label={field.label}
            value={field.values.length > 0 ? field.values[0] : field.value}
            fields={allFields}
          />
        </Suspense>
      );
    case InputType.Slider:
      return (
        <MultiSlider selectOptions={field.selectOptions.slice().reverse()} label={field.label} onChange={onChange} />
      );
    case InputType.Percentage:
      return (
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          id={field.id.toString()}
          slotProps={{
            htmlInput: { readOnly: isReadOnly },
            input: {
              type: 'number',
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            },
          }}
          disabled={isDisabled}
          type={field.type}
          label={field.label}
          defaultValue={Number((Number.parseFloat(field.value) * 100).toFixed(3))}
          onChange={(event) => {
            const value = Number.parseFloat(event.target.value) / 100;
            if (!Number.isNaN(value)) {
              onChange((Number(event.target.value) / 100).toString());
            }
          }}
        />
      );
    case InputType.Password:
      return <PasswordTextfield field={field} isDisabled={isDisabled} sx={sx} onChange={onChange} />;
    default:
      return (
        <TextField
          variant="outlined"
          className={className}
          sx={mergeSx(sx ?? {}, (theme) => ({
            background: hasWhiteFields ? theme.palette.background.paper : 'initial',
            borderRadius: theme.spacing(1),
          }))}
          margin="dense"
          autoFocus={autoFocus}
          disabled={isDisabled}
          fullWidth
          slotProps={{ htmlInput: { readOnly: isReadOnly } }}
          id={field.id.toString()}
          type={field.type}
          label={field.label}
          value={field.value}
          onChange={(event) => onChange(event.target.value || '')}
        />
      );
  }
};

export default DynamicInputField;
