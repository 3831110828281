import { Typography, Box, Stack } from '@mui/material';
import { FaHourglassHalf } from 'react-icons/fa';
import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import { BaseTheme, baseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import { ButtonColor } from 'generated/graphql';
import Chip from './Chip';

const CHIP_CIRCUMFERENCE = 25;

export interface QualityChip {
  color: ButtonColor;
  value?: string;
}

export enum QualityChipVariant {
  Stacked = 'Stacked',
  BorderedCollapsed = 'BorderedCollapsed',
  BorderedExpanded = 'BorderedExpanded',
  Simple = 'Simple',
  BadgeLabel = 'BadgeLabel',
  BadgeLabelBottom = 'BadgeLabelBottom',
  BadgeNoLabel = 'BadgeNoLabel',
  Dot = 'Dot',
}

export enum QualityChipSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

const QualityChipIcon = ({ size = 16, color = ButtonColor.Success }: { size?: number; color?: ButtonColor }) => {
  switch (color) {
    case ButtonColor.Warning:
      return (
        <MdOutlineError
          size={size}
          style={{
            color: baseTheme.palette.warning.main,
          }}
        />
      );
    case ButtonColor.Error:
      return (
        <MdCancel
          size={size}
          style={{
            color: baseTheme.palette.error.dark,
          }}
        />
      );
    case ButtonColor.Success:
      return (
        <MdCheckCircle
          size={size}
          style={{
            color: baseTheme.palette.success.light,
          }}
        />
      );
    default:
      return (
        // Wrapped hourglass icon in stack since it does not have a background circle like other QC icons
        <Stack
          sx={(theme) => ({
            justifyContent: 'center',
            alignItems: 'center',
            width: size,
            height: size,
            borderRadius: theme.spacing(5),
            border: `3px solid ${theme.palette.common.white}`,
            background: theme.palette.grey[400],
          })}
        >
          <FaHourglassHalf
            size={12}
            style={{
              color: baseTheme.palette.common.white,
            }}
          />
        </Stack>
      );
  }
};

const QualityChipsStacked = ({ chips }: { chips: QualityChip[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: 45,
      }}
    >
      {/* Grey back-most circle */}
      {chips[2] && (
        <Box
          component="span"
          sx={(theme) => ({
            position: 'relative',
            width: CHIP_CIRCUMFERENCE,
            height: CHIP_CIRCUMFERENCE,
            borderRadius: theme.spacing(5),
            border: `3px solid ${theme.palette.common.white}`,
            marginRight: theme.spacing(-2),
            opacity: 0.5,
            background: theme.palette.grey[600],
          })}
        />
      )}

      {/* Middle chip */}
      {chips[1] && (
        <Box
          component="span"
          sx={(theme) => ({
            position: 'relative',
            width: CHIP_CIRCUMFERENCE,
            height: CHIP_CIRCUMFERENCE,
            background: theme.palette.common.white,
            borderRadius: theme.spacing(5),
            marginRight: theme.spacing(-1.75),
          })}
        >
          <QualityChipIcon size={CHIP_CIRCUMFERENCE} color={chips[1].color} />
        </Box>
      )}

      {/* Front chip */}
      {chips[0] && (
        <Box
          component="span"
          sx={(theme) => ({
            position: 'relative',
            width: CHIP_CIRCUMFERENCE,
            height: CHIP_CIRCUMFERENCE,
            background: theme.palette.common.white,
            borderRadius: theme.spacing(5),
            padding: 'auto',
          })}
        >
          <Box sx={{ margin: 'auto', width: '100%', height: '100%' }}>
            <QualityChipIcon size={CHIP_CIRCUMFERENCE} color={chips[0].color} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const getBackgroundColor = (theme: BaseTheme, color: ButtonColor) => {
  switch (color.toLowerCase()) {
    case ButtonColor.Warning.toLowerCase():
      return theme.palette.warning.main;
    case ButtonColor.Error.toLowerCase():
      return theme.palette.error.dark;
    case ButtonColor.Success.toLowerCase():
      return theme.palette.success.light;
    default:
      return theme.palette.grey[400];
  }
};

const QualityChipsBordered = ({ chips, hasLabel = false }: { chips: QualityChip[]; hasLabel?: boolean }) => {
  const { isEnabled } = useFeatures();
  const isPriceGuidelineUIEnabled = isEnabled('PriceGuidelineUI');
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.5,
        flexWrap: 'wrap',
      }}
    >
      {chips.map((chip, index) => (
        <Box
          key={index}
          component="span"
          sx={(theme) => ({
            height: CHIP_CIRCUMFERENCE,
            background: theme.palette.common.white,
            borderRadius: theme.spacing(5),
            border: `1.25px solid`,
            borderColor: getBackgroundColor(theme, chip.color),
          })}
        >
          <Box
            sx={{ margin: 'auto', display: 'flex', width: isPriceGuidelineUIEnabled ? 'max-content' : 'fit-content' }}
          >
            <QualityChipIcon size={CHIP_CIRCUMFERENCE - 2} color={chip.color} />
            {hasLabel && (
              <Typography
                variant={'body2'}
                sx={(theme) => ({
                  color: getBackgroundColor(theme, chip.color),
                  alignContent: 'center',
                  marginRight: theme.spacing(0.5),
                  paddingLeft: theme.spacing(0.5),
                })}
              >
                {chip.value}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const QualityChipsSimple = ({
  chips,
  size = QualityChipSize.Medium,
}: {
  chips: QualityChip[];
  size?: QualityChipSize;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        position: 'inherit',
        top: 'auto',
        right: 'auto',
      }}
    >
      {chips.map((chip, index) => (
        <Box key={`${chip.value}-${index}`}>
          <Chip
            key={index}
            label={chip.value}
            variant="filled"
            clickable={false}
            sx={(theme) => ({
              background:
                chip.color?.toLowerCase() === ButtonColor.Warning.toLowerCase()
                  ? theme.palette.warning.main
                  : chip.color?.toLowerCase() === ButtonColor.Error.toLowerCase()
                    ? theme.palette.error.dark
                    : chip.color?.toLowerCase() === ButtonColor.Success.toLowerCase()
                      ? baseTheme.palette.success.light
                      : baseTheme.palette.grey[400],
              '& .MuiChip-label': {
                color: 'white',
                fontWeight: theme.typography.fontWeightRegular,
                padding: size === QualityChipSize.Small ? theme.spacing(0.2, 0.5) : theme.spacing(0.1, 2),
              },
              height: size === QualityChipSize.Small ? theme.spacing(3) : theme.spacing(4),
            })}
          />
        </Box>
      ))}
    </Box>
  );
};

const QualityChipsBadge = ({
  chips,
  hasLabel = false,
  isBottom = false,
}: {
  chips: QualityChip[];
  hasLabel?: boolean;
  isBottom?: boolean;
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: 0.5,
        position: 'absolute',
        top: isBottom ? 'initial' : theme.spacing(0.5),
        bottom: isBottom ? theme.spacing(1.5) : 'initial',
        right: isBottom ? '50%' : theme.spacing(0.5),
        transform: isBottom ? 'translateX(50%)' : 'initial',
      })}
    >
      {chips.map((chip, index) => (
        <Box key={`${chip.value}-${index}`}>
          <Box
            key={index}
            sx={(theme) => ({
              borderRadius: theme.spacing(5),
              margin: hasLabel && chip.value ? theme.spacing(1, 1, 0, 0) : 0,
              padding: hasLabel && chip.value ? theme.spacing(0.2, 1) : theme.spacing(0.7),
              zIndex: 4,
              background:
                chip.color?.toLowerCase() === ButtonColor.Warning.toLowerCase()
                  ? theme.palette.warning.main
                  : chip.color?.toLowerCase() === ButtonColor.Error.toLowerCase()
                    ? theme.palette.error.dark
                    : chip.color?.toLowerCase() === ButtonColor.Success.toLowerCase()
                      ? baseTheme.palette.success.light
                      : baseTheme.palette.grey[400],
            })}
          >
            {hasLabel && chip.value && (
              <Typography variant="body2" sx={(theme) => ({ color: theme.palette.common.white })}>
                {chip.value}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const QualityChipsDot = ({ chips }: { chips: QualityChip[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.5,
      }}
    >
      {chips.map((chip, index) => (
        <Box key={`${chip.value}-${index}`}>
          <Box
            key={index}
            sx={(theme) => ({
              borderRadius: theme.spacing(5),
              margin: 0,
              padding: theme.spacing(0.7),
              background: getBackgroundColor(theme, chip.color),
            })}
          />
        </Box>
      ))}
    </Box>
  );
};

export const QualityChips = ({
  chips,
  variant = QualityChipVariant.BorderedCollapsed,
  size = QualityChipSize.Medium,
}: {
  chips: QualityChip[];
  variant?: QualityChipVariant;
  size?: QualityChipSize;
}) => {
  switch (variant) {
    case QualityChipVariant.Stacked:
      return <QualityChipsStacked chips={chips} />;
    case QualityChipVariant.BorderedExpanded:
      return <QualityChipsBordered chips={chips} hasLabel />;
    case QualityChipVariant.BorderedCollapsed:
      return <QualityChipsBordered chips={chips} />;
    case QualityChipVariant.Simple:
      return <QualityChipsSimple chips={chips} size={size} />;
    case QualityChipVariant.BadgeLabel:
      return <QualityChipsBadge chips={chips} hasLabel />;
    case QualityChipVariant.BadgeLabelBottom:
      return <QualityChipsBadge chips={chips} hasLabel isBottom />;
    case QualityChipVariant.Dot:
      return <QualityChipsDot chips={chips} />;
    default:
      return <QualityChipsBadge chips={chips} />;
  }
};

export default QualityChip;
