import { captureException, captureFeedback } from '@sentry/react';
import { AuthUser } from 'interfaces/user';
import { VERSION } from 'version';

const TRACING_KEY = 'tracing';
const MAX_TRACING_ELEMENTS = 20;

export enum Priority {
  High = 'High',
  Normal = 'Normal',
  Low = 'Low',
}
export const PRIORITIES = Object.values(Priority);

export enum Category {
  Order = 'Help with an order',
  Profile = 'Help with my profile',
  Other = 'Other',
}
export const CATEGORIES = Object.values(Category);

interface TracingInfo {
  traceId: string;
  timestamp: string;
  version: string;
}

export const storeTraceID = (traceID: string) => {
  let tracingArray = localStorage.getItem(TRACING_KEY);
  let tracing: TracingInfo[] = [];
  if (tracingArray) {
    tracing = JSON.parse(tracingArray);
  }
  if (tracing.length >= MAX_TRACING_ELEMENTS) {
    tracing.shift();
  }

  const tracingInfo: TracingInfo = {
    traceId: traceID,
    timestamp: new Date().toISOString(),
    version: VERSION,
  };
  tracing.push(tracingInfo);

  localStorage.setItem(TRACING_KEY, JSON.stringify(tracing));
};

export const getTracingInfo: () => TracingInfo[] = () => {
  let tracingArray = localStorage.getItem(TRACING_KEY);
  return tracingArray ? JSON.parse(tracingArray) : [];
};

export const clearTracingInfo = () => {
  localStorage.removeItem(TRACING_KEY);
};

export const reportTracingInfo = (
  user: AuthUser | null,
  category = Category.Profile,
  description = '',
  priority = Priority.Normal,
) => {
  const tracingInfo = getTracingInfo();

  // Generate a unique fingerprint to ensure this error is not grouped
  const fingerprint = [
    'user-triggered-error',
    category,
    priority,
    description,
    user?.email || 'anonymous',
    Date.now().toString(),
  ];

  const exceptionID = captureException(new Error('User triggered error'), {
    tags: {
      user_action: 'error_logged',
      feature: 'error_reporting',
    },
    contexts: {
      user: {
        ...user,
      },
    },
    extra: {
      tracingInfo: tracingInfo,
      category: category,
      description: description,
      priority: priority,
      version: VERSION,
    },
    fingerprint,
  });

  captureFeedback(
    {
      name: user?.name || 'Anonymous',
      email: user?.email || '',
      message: description,
    },
    {
      includeReplay: true,
      attachments: [],
      event_id: exceptionID,
      data: {
        tracingInfo: tracingInfo,
        category: category,
        description: description,
        priority: priority,
        version: VERSION,
      },
    },
  );
};
