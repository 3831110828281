import { useEffect } from 'react';
import useBuyersMarketStateStore from 'store/useBuyersMarketStateStore';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { isMarketStateSet, DEFAULT_ADMIN_PROFILE_ID } from 'utils/isMarketStateSet';
import { GroupOption, MarketMetric, ShippingMethod } from 'generated/graphql';
import useSearch from './useSearch';
import useUser from './useUser';

const useInitializeMarketStateStore = () => {
  const buyersMarketStateStore = useBuyersMarketStateStore();
  const { isSeller, user, isAdmin } = useUser();
  const [getParam] = useSearch();
  const { isVillaProfile } = useWhiteLabelling();
  const defaultShippingMethod =
    ((getParam('shippingMethod') as ShippingMethod) ?? isVillaProfile) ? ShippingMethod.Direct : ShippingMethod.Hub;

  useEffect(() => {
    if (!isMarketStateSet(buyersMarketStateStore.input) && !isSeller) {
      const currBuyerID = isAdmin ? getParam('buyerID') : `${user?.profileId}`;

      const queryTruckType = getParam('truckTypeUUID');
      buyersMarketStateStore.setInput({
        search: '',
        filters: [],
        truckTypeUUID: queryTruckType ?? DEFAULT_ADMIN_PROFILE_ID,
        buyerID: currBuyerID ?? DEFAULT_ADMIN_PROFILE_ID,
        method: defaultShippingMethod,
        groupOption: GroupOption.Seller,
        metric: MarketMetric.Pallets,
      });
    }

    if (buyersMarketStateStore.input?.search === undefined) {
      buyersMarketStateStore.setInput({
        search: '',
      });
    }
  }, [buyersMarketStateStore, defaultShippingMethod, getParam, isAdmin, isSeller, user]);

  return buyersMarketStateStore;
};

export default useInitializeMarketStateStore;
