import { create } from 'zustand';
import { DEFAULT_ADMIN_PROFILE_ID, DEFAULT_EMPTY_ID_STATE } from 'utils/isMarketStateSet';
import { MarketInput, MarketMetric, GroupOption, ShippingMethod, RefetchType } from 'generated/graphql';

const useBuyersMarketStateStore = create<{
  input: MarketInput;
  setInput: (newInput: Partial<MarketInput>) => void;
}>((set) => ({
  input: {
    buyerID: DEFAULT_EMPTY_ID_STATE,
    filters: [],
    groupOption: GroupOption.Seller,
    method: ShippingMethod.Hub,
    metric: MarketMetric.Pallets,
    paginationOption: { pageNumber: 1, pageSize: 50, ids: [], type: RefetchType.Unknown },
    search: '',
    truckTypeUUID: DEFAULT_ADMIN_PROFILE_ID,
  },
  setInput: (newInput: Partial<MarketInput>) =>
    set((store) => ({
      input: {
        ...store.input,
        ...newInput,
      },
    })),
}));

export default useBuyersMarketStateStore;
