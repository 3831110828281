import { addBreadcrumb } from '@sentry/react';
import { ComponentType, MouseEvent } from 'react';
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types';
import { logAnalytics } from 'utils/logAnalytics';

export const defaultElement = 'button';

type WithClickTracking = {
  eventParams?: { [key: string]: string | number };
  onClick?: (event: MouseEvent) => void;
};

function withClickTracking<Props>(WrappedComponent: ComponentType<Props>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  type CompProps<T extends React.ElementType = typeof defaultElement> = PolymorphicPropsWithoutRef<
    Props & WithClickTracking,
    T
  >;

  function Comp<T extends React.ElementType = typeof defaultElement>({
    eventParams,
    onClick,
    as,
    ...rest
  }: CompProps<T>) {
    const Element: React.ElementType = as || defaultElement;

    const handleClick = (event: MouseEvent) => {
      if (onClick) {
        // Safely access children (button text) and provide a fallback if undefined.
        const label = event.currentTarget.textContent?.trim() || 'Button';
        const params = {
          label,
          ...eventParams,
        };
        logAnalytics('select_content', params);
        onClick(event);
        addBreadcrumb({
          category: 'click',
          message: `Clicked ${displayName} with label: ${label} on component ${WrappedComponent.name}`,
          data: params,
        });
      }
    };

    return <WrappedComponent component={Element} {...(rest as Props)} onClick={handleClick} />;
  }

  Comp.displayName = `withClickTracking(${displayName})`;
  return Comp;
}

export default withClickTracking;
