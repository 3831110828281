import { init, browserTracingIntegration, browserProfilingIntegration } from '@sentry/react';
import 'proxy-polyfill';
import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import { clearStore } from 'services/idb-store';
import { logError } from 'services/logging';
import 'typeface-nunito';
import 'typeface-roboto';
import 'typeface-roboto-mono';
import { registerSW } from 'virtual:pwa-register';
import { isProd } from 'config/env';
import App from './App';
import './index.css';

if (isProd) {
  const SENTRY_DSN = 'https://c878a8a16b0b40888c12b0cbb06ff04e@o4504723873071104.ingest.sentry.io/4504723878248448';
  init({
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Failed to fetch dynamically imported module',
      'Firebase: Error (auth/network-request-failed)',
      'UnknownError: The user denied permission to access the database',
      'Messaging: The notification permission was not granted and blocked instead',
      'Your profile is not active, contact support for information',
    ],
    dsn: SENTRY_DSN,
    environment: 'production',
    maxBreadcrumbs: 50,
    integrations: [browserTracingIntegration(), browserProfilingIntegration()],
    tracesSampleRate: 0.5,
  });

  const FACEBOOK_TRACKING_PIXEL = '1119188959501360';
  ReactPixel.init(FACEBOOK_TRACKING_PIXEL);
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);

const FIFTEEN_MINUTES = 15 * 60 * 1000;
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

if (!isIOS()) {
  try {
    registerSW({
      immediate: true,
      onRegistered(registration) {
        if (registration) {
          // Clear IndexedDB cache on new service worker registration to avoid oudated queries from being used.
          clearStore();
          setInterval(() => {
            if (!(!registration.installing && navigator)) return;
            if ('connection' in navigator && !navigator.onLine) return;
            registration.update().catch((error) => logError(error));
          }, FIFTEEN_MINUTES);
          // Update service worker on visibility change, to ensure the latest version is used.
          document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
              try {
                if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                  registration.update().catch((error) => logError(error));
                }
              } catch (err: any) {
                logError(new Error(`Error checking for service worker update: ${err.message}`));
              }
            }
          });
        }
      },
    });
  } catch (err: any) {
    logError(new Error(`Service worker registration failed. Error: ${err.message}`));
  }
}
