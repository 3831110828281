import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { VERSION } from 'version';
import Button from './Button';

const UpdateVersion = () => {
  const [open, setOpen] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    const currVersionDate = dayjs(Number(VERSION));

    if (document.visibilityState === 'visible' && dayjs().diff(currVersionDate, 'days') > 60) {
      setOpen(true);
    } else if (open) {
      setOpen(false);
    }
  }, [open]);

  useEffect(() => {
    handleVisibilityChange();
  }, [handleVisibilityChange]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <Typography variant="h6" color="primary">
          Update required
        </Typography>
        <Typography sx={(theme) => ({ marginTop: theme.spacing(1) })} variant="body1" color="textSecondary">
          Update to the latest version to catch exciting new features in our app.
        </Typography>
      </DialogContent>
      <DialogActions sx={(theme) => ({ paddingX: theme.spacing(2.5), paddingBottom: theme.spacing(2) })}>
        <Button onClick={() => window.location.reload()} fullWidth variant="contained">
          Update now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateVersion;
