import { UserType } from 'interfaces/user';
import { BsPeopleFill } from 'react-icons/bs';
import { FaShoppingBag, FaTruck } from 'react-icons/fa';
import { MdShoppingCart, MdOutlineReceiptLong, MdPieChart, MdExplore } from 'react-icons/md';
import { getRoutePrefix } from 'services/theming';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import { paths } from 'config/paths';
import { Permission, Scope, useAccess } from './useAccess';
import useUser from './useUser';

const useNavigationItems = () => {
  const { user } = useUser();
  const { isVillaProfile } = useWhiteLabelling();
  const has = useAccess();
  const hasCustomersAccess = has(Permission.Customers, Scope.Read);
  const hasCartAccess = has(Permission.Market, Scope.Write);
  const hasMarketAccess = has(Permission.Market, Scope.Read);
  const prefix = getRoutePrefix();

  if (!user?.userType)
    return [
      {
        label: 'Market',
        path: `${prefix}?page=${paths.market()}`,
        icon: FaShoppingBag,
      },
      { label: 'Orders', path: `${prefix}?page=${paths.shipments()}`, icon: MdOutlineReceiptLong },
      { label: 'Reports', path: `${prefix}?page=${paths.reports()}`, icon: MdPieChart },
      { label: 'News', path: `${prefix}?page=${paths.explore()}`, icon: MdExplore },
    ];

  const showCart = user.userType !== UserType.Seller && hasCartAccess;
  const showCustomers = user.userType === UserType.Admin && hasCustomersAccess;
  const showLogistics = user.userType === UserType.Seller;
  const showSellerMarket = user.userType === UserType.Seller;
  const showReports = user.userType === UserType.Seller;

  return [
    ...(hasMarketAccess
      ? [
          {
            label: 'Market',
            path: showSellerMarket ? paths.sellerMarket() : paths.market(),
            icon: FaShoppingBag,
          },
        ]
      : []),
    ...(showCart
      ? [
          {
            label: 'Cart',
            path: paths.cart(),
            icon: MdShoppingCart,
          },
        ]
      : []),
    { label: 'Orders', path: paths.shipments(), icon: MdOutlineReceiptLong },
    ...(showCustomers ? [{ label: 'Customers', path: paths.customers(), icon: BsPeopleFill }] : []),
    ...(showLogistics ? [{ label: 'Logistics', path: paths.logistics(), icon: FaTruck }] : []),
    ...(showReports ? [{ label: 'Reports', path: paths.reports(), icon: MdPieChart }] : []),
    ...(!isVillaProfile ? [{ label: 'News', path: paths.explore(), icon: MdExplore }] : []),
  ];
};

export default useNavigationItems;
