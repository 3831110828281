import { Snackbar, useTheme } from '@mui/material';
import { setTag, setUser as setSentryUser } from '@sentry/react';
import { signInWithCustomToken } from 'firebase/auth';
import { AuthUser } from 'interfaces/user';
import { useState, ReactNode, useEffect, useCallback } from 'react';
import { logout } from 'services/auth';
import { auth } from 'services/firebase';
import { getPrefixedRoute, getThemeVariant } from 'services/theming';
import useUser, { getUserFromParsedIdToken } from 'hooks/useUser';
import refreshFirebaseUser from 'utils/refresh-firebase-user';
import { setUserAnalytics } from 'utils/setUserAnalytics';

const APP_TOKEN_STORAGE_KEY = 'appToken';

const AuthCheck = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const [isInitialised, setIsInitialised] = useState(false);
  const [message, setMessage] = useState('');
  const { user, setUser } = useUser();

  const handleUserRefresh = useCallback(async () => {
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) {
        await logout(false);
        return;
      }
      await refreshFirebaseUser({
        user: firebaseUser,
        setUser,
      });
    } catch (error) {
      setMessage('Failed to authenticate');
      window.location.replace(getPrefixedRoute('/login'));
    } finally {
      setIsInitialised(true);
    }
  }, [setUser]);

  const handleAppTokenLogin = useCallback(
    async (appToken: string) => {
      try {
        const userCredential = await signInWithCustomToken(auth, appToken);
        const authUser = await userCredential?.user?.getIdTokenResult(true);

        if (!authUser) {
          throw new Error('Failed to get user please try log in again');
        }

        if (authUser?.claims?.status === 'Rejected') {
          throw new Error('Your profile is not active, contact support for information');
        }

        localStorage.setItem(APP_TOKEN_STORAGE_KEY, '');
        setUser(getUserFromParsedIdToken(authUser));
        setIsInitialised(true);
      } catch (error) {
        localStorage.setItem(APP_TOKEN_STORAGE_KEY, '');
        handleUserRefresh();
      }
    },
    [setUser, handleUserRefresh],
  );

  useEffect(() => {
    if (isInitialised) return;

    try {
      const appToken = localStorage.getItem(APP_TOKEN_STORAGE_KEY);
      if (appToken) {
        handleAppTokenLogin(appToken);
      } else {
        handleUserRefresh();
      }
    } catch (error) {
      handleUserRefresh();
    }
  }, [handleAppTokenLogin, handleUserRefresh, isInitialised]);

  useEffect(() => {
    if (user && isInitialised) {
      setSentryUser({
        id: user.userId,
        email: user.email,
      });
      setTag('theme', getThemeVariant());

      Object.keys(user).forEach((key) => {
        setTag(key, JSON.stringify(user[key as keyof AuthUser]));
      });

      setUserAnalytics(user.email);
    }
  }, [user, theme, isInitialised]);

  if (!isInitialised) {
    return null;
  }

  return (
    <>
      {children}
      <Snackbar open={!!message} onClose={() => setMessage('')} message={message} />
    </>
  );
};

export default AuthCheck;
