import { useEffect, useState } from 'react';
import { DEFAULT_PAGE_NUMBER } from 'utils/constants';
import {
  DateInfo,
  DateStatus,
  BuyersMarketResultsQuery,
  MarketSellerResults,
  MarketTypeResults,
  MarketGroupResults,
  MarketProduct,
  MarketSectionResults,
  MarketProductMetricInfo,
} from 'generated/graphql';

// Function to merge availability data
const stitchAvailability = (currAvailability: DateInfo[] | undefined, newAvailability: DateInfo[]): DateInfo[] => {
  if (!currAvailability) {
    return newAvailability ?? [];
  }
  return currAvailability.map((availability) => {
    const marketAvailability = newAvailability.find((x) => x.date === availability.date);

    if (!marketAvailability) {
      return { ...availability };
    }

    return {
      ...marketAvailability,
      status: [availability.status, marketAvailability.status].includes(DateStatus.Available)
        ? DateStatus.Available
        : marketAvailability.status,
    };
  });
};

const stitchMetricInfo = (
  currMetricInfo: MarketProductMetricInfo[] | undefined,
  newMetricInfo: MarketProductMetricInfo[],
): MarketProductMetricInfo[] => {
  if (!currMetricInfo) {
    return newMetricInfo ?? [];
  }
  return currMetricInfo.map((metricInfo) => {
    const marketMetricInfo = newMetricInfo.find((x) => x.metric === metricInfo.metric);

    if (!marketMetricInfo) {
      return { ...metricInfo };
    }

    return {
      ...metricInfo,
      discount: marketMetricInfo.discount ?? metricInfo.discount,
      availability: stitchAvailability(metricInfo.availability, marketMetricInfo.availability),
    };
  });
};

const stitchProducts = (products: MarketProduct[] | undefined, productsNew: MarketProduct[]): MarketProduct[] => {
  if (!products || !productsNew) {
    return productsNew;
  }

  const indexesToSplice: Number[] = [];

  const data = products.map((product) => {
    const index = productsNew.findIndex((curr) => curr.id === product.id);

    if (index === -1) {
      return { ...product };
    }

    const productNew = productsNew[index];
    indexesToSplice.push(index);

    return {
      ...product,
      metricInfo: stitchMetricInfo(product.metricInfo, productNew.metricInfo),
    };
  });

  const dataToAdd = productsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(data || []), ...(dataToAdd || [])];
};

const stitchGroups = (
  groupResults: MarketGroupResults[] | undefined,
  groupResultsNew: MarketGroupResults[],
): MarketGroupResults[] => {
  if (!groupResults || !groupResultsNew) {
    return groupResultsNew;
  }

  const indexesToSplice: Number[] = [];

  const data = groupResults.map((group) => {
    const index = groupResultsNew.findIndex((curr) => curr.id === group.id);

    if (index === -1) {
      return { ...group };
    }

    const groupNew = groupResultsNew[index];
    indexesToSplice.push(index);

    return {
      ...group,
      products: stitchProducts(group.products, groupNew.products),
    };
  });

  const dataToAdd = groupResultsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(data || []), ...(dataToAdd || [])];
};

const stitchTypes = (
  typeResults: MarketTypeResults[] | undefined,
  typeResultsNew: MarketTypeResults[],
): MarketTypeResults[] => {
  if (!typeResults || !typeResultsNew) {
    return typeResultsNew;
  }

  const indexesToSplice: Number[] = [];

  const data = typeResults.map((typeResult) => {
    const index = typeResultsNew.findIndex((curr) => curr.id === typeResult.id);

    if (index === -1) {
      return { ...typeResult };
    }

    const typeResultNew = typeResultsNew[index];
    indexesToSplice.push(index);

    return {
      ...typeResult,
      groups: stitchGroups(typeResult.groups, typeResultNew.groups),
    };
  });

  const dataToAdd = typeResultsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(data || []), ...(dataToAdd || [])];
};

const stitchSellers = (
  sellerResults: MarketSellerResults[] | undefined,
  sellerResultsNew: MarketSellerResults[],
): MarketSellerResults[] => {
  if (!sellerResults || !sellerResultsNew) {
    return sellerResultsNew;
  }

  const indexesToSplice: Number[] = [];

  const data = sellerResults.map((sellerResult) => {
    const index = sellerResultsNew.findIndex((curr) => curr.seller.id === sellerResult.seller.id);

    if (index === -1) {
      return { ...sellerResult };
    }

    const sellerResultNew = sellerResultsNew[index];
    indexesToSplice.push(index);

    return {
      ...sellerResult,
      types: stitchTypes(sellerResult.types, sellerResultNew.types),
    };
  });

  const dataToAdd = sellerResultsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(data || []), ...(dataToAdd || [])];
};

const stitchSections = (sections: MarketSectionResults[], sectionsNew: MarketSectionResults[]) => {
  if (!sections || !sectionsNew) {
    return sectionsNew;
  }

  const indexesToSplice: Number[] = [];

  const data = sections.map((section) => {
    const index = sectionsNew.findIndex((curr) => curr.ID === section.ID);

    if (index === -1) {
      return { ...section };
    }

    const marketSection = sectionsNew[index];
    indexesToSplice.push(index);

    return {
      ...section,
      sellers: stitchSellers(section.sellers, marketSection.sellers),
    };
  });

  const dataToAdd = sectionsNew.filter((_, index) => !indexesToSplice.includes(index));

  return [...(data || []), ...(dataToAdd || [])];
};

const stitchMarket = (
  marketResultQuery: BuyersMarketResultsQuery | undefined,
  marketResultQueryNew: BuyersMarketResultsQuery,
  isFirstPage = false,
): BuyersMarketResultsQuery => {
  if (!marketResultQuery || !marketResultQueryNew) {
    return marketResultQueryNew;
  }

  return {
    ...marketResultQuery,
    market: {
      ...marketResultQuery.market,
      results: {
        page: marketResultQueryNew.market.results.page,
        sections:
          (marketResultQueryNew?.market?.results?.sections?.length ?? 0) === 0 && isFirstPage
            ? []
            : stitchSections(marketResultQuery.market.results.sections, marketResultQueryNew.market.results.sections),
      },
    },
  };
};

export const useStitchBuyerMarketResults = (data: BuyersMarketResultsQuery | undefined) => {
  const [marketQuery, setMarketQuery] = useState<BuyersMarketResultsQuery>();
  const [isStitching, setIsStitching] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setIsStitching(true);
      const isFirstPage = data?.market?.results?.page?.page === DEFAULT_PAGE_NUMBER;
      setMarketQuery((prev) => (isFirstPage ? data : stitchMarket(prev, data, isFirstPage)));
    }
  }, [data]);

  useEffect(() => {
    setIsStitching(false);
  }, [marketQuery]);

  return {
    marketQuery: marketQuery,
    setMarketQuery: setMarketQuery,
    stitching: isStitching,
  };
};
