import { baseTheme } from 'config/base-theme';
import { TableType, ThemeColor } from 'generated/graphql';

const stickyColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 1,
  boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
  borderBottom: 'none',
  borderRight: 'none',
};
const stickyRowStyle = (index: number) => ({
  position: 'sticky',
  top: 0,
  zIndex: index === 0 ? 3 : 2,
  boxShadow: 'inset 0 -10px 1px -10px rgba(0,0,0,0.4)',
  borderBottom: 'none',
});

const defaultTableContainerStyle = () => ({
  '&::-webkit-scrollbar': {
    height: baseTheme.spacing(1),
  },
  scrollbarWidth: baseTheme.spacing(1),
  border: baseTheme.palette.tertiary?.main,
  borderRadius: baseTheme.spacing(1),
  maxHeight: 'auto',
});

const detailedTableContainerStyle = () => ({
  ...defaultTableContainerStyle(),
  border: `1px solid ${baseTheme.palette.grey[400]}`,
});

const statisticsTableContainerStyle = () => ({
  ...defaultTableContainerStyle(),
  border: 'none',
  borderRadius: baseTheme.spacing(0),
  maxHeight: 'calc(100vh - 76px - 48px - 105px)',
});

const reportingTablContainerStyle = () => ({
  ...defaultTableContainerStyle(),
  border: 'none',
});

const defaultTableStyle = () => ({
  borderCollapse: 'separate',
  borderRadius: baseTheme.spacing(1),
  [baseTheme.breakpoints.down('md')]: {
    background: baseTheme.palette.tertiary?.main,
  },
});

const detailedTableStyle = () => ({
  borderCollapse: 'separate',
  borderRadius: baseTheme.spacing(1),
});

const statisticsTableStyle = () => ({
  '& .MuiTableCell-root': {
    border: `1px solid ${baseTheme.palette.grey[300]}`,
    backgroundColor: baseTheme.palette.common.white,
    textAlign: 'left',
    padding: baseTheme.spacing(1),
  },
  '& .MuiTableCell-head': {
    fontWeight: baseTheme.typography.fontWeightBold,
    color: baseTheme.palette.common.black,
    fontSize: baseTheme.spacing(1.5),
  },
});

const basicTableStyle = () => ({
  ...defaultTableStyle(),
  [baseTheme.breakpoints.down('md')]: {
    borderRadius: baseTheme.spacing(1),
  },
});

const defaultColumnStyle = () => ({
  verticalAlign: 'top',
  padding: baseTheme.spacing(0.5, 1.5, 0.25, 1.5),
  color: baseTheme.palette.text.secondary,
  minWidth: 102,
  borderBottom: `1px solid ${baseTheme.palette.common.white}`,
});

const detailedColumnStyle = () => ({
  ...defaultColumnStyle(),
  borderBottom: `1px solid ${baseTheme.palette.grey[300]}`,
});

const statisticsColumnStyle = (index: number, textAlign: string) => ({
  ...defaultColumnStyle(),
  '&.MuiTableCell-root': {
    borderRight: index > 0 ? 'hidden' : '',
    borderLeft: index === 0 ? 'hidden' : '',
    borderTop: 'hidden',
    minWidth: '120px',
    width: '170px',
    maxWidth: '200px',
    paddingY: baseTheme.spacing(2),
    textAlign,
    ...(index === 0 && stickyColumnStyle),
    ...stickyRowStyle(index),
  },
});

const basicColumnStyle = (index: number) => ({
  ...defaultColumnStyle(),
  '&.MuiTableCell-root': {
    color: baseTheme.palette.primary.main,
    padding: baseTheme.spacing(2, 1.5),
    [baseTheme.breakpoints.down('md')]: {
      padding: baseTheme.spacing(1, 0.5),
    },
    borderBottom: 'none',
    ...(index === 0 && {
      minWidth: '100px',
      position: 'sticky',
      left: 0,
      zIndex: 1,
      background: baseTheme.palette.tertiary.light,
    }),
  },
});

export const getTableContainerStyle = (type: TableType) => {
  switch (type) {
    case TableType.Detail:
      return detailedTableContainerStyle();
    case TableType.Statistics:
      return statisticsTableContainerStyle();
    case TableType.Reporting:
      return reportingTablContainerStyle();
    default:
      return defaultTableStyle();
  }
};

export const getTableStyle = (type: TableType) => {
  switch (type) {
    case TableType.Detail:
      return detailedTableStyle();
    case TableType.Statistics:
      return statisticsTableStyle();
    case TableType.Basic:
      return basicTableStyle();
    default:
      return defaultTableStyle();
  }
};

export const getTableColumnStyle = (type: TableType, index = 0, textAlign = 'right') => {
  switch (type) {
    case TableType.Detail:
      return detailedColumnStyle();
    case TableType.Statistics:
      return statisticsColumnStyle(index, textAlign);
    case TableType.Basic:
      return basicColumnStyle(index);
    default:
      return defaultColumnStyle();
  }
};

const TableRowDefaultBackgroundColor = (type: TableType) => {
  switch (type) {
    case TableType.Detail:
      return { background: baseTheme.palette.background.paper };
    case TableType.Basic:
      return { background: baseTheme.palette.background.paper };
    case TableType.Reporting:
      return { background: baseTheme.palette.tertiary.main };
    default:
      return {
        background: baseTheme.palette.tertiary?.light,
        [baseTheme.breakpoints.down('md')]: {
          background: baseTheme.palette.tertiary?.main,
        },
      };
  }
};

const TableRowBackgroundColor = (color: ThemeColor, type: TableType) => {
  switch (color) {
    case ThemeColor.Primary:
      return { background: baseTheme.palette.primary.light + '50' };
    case ThemeColor.Secondary:
      return { background: baseTheme.palette.secondary.light + '50' };
    case ThemeColor.Info:
      return { background: baseTheme.palette.info.light + '50' };
    case ThemeColor.Warning:
      return { background: baseTheme.palette.warning.light + '50' };
    case ThemeColor.Success:
      return { background: baseTheme.palette.success.light + '50' };
    case ThemeColor.Error:
      return { background: baseTheme.palette.error.light + '30' };
    default:
      return TableRowDefaultBackgroundColor(type);
  }
};

export const TableRowStyle = (isHidden: boolean, color: ThemeColor, type: TableType) => ({
  cursor: 'pointer',
  margin: baseTheme.spacing(1, 0),
  height: 49,
  display: isHidden ? 'none' : 'table-row',
  ...TableRowBackgroundColor(color, type),
});
